<template>
  <v-container fluid class="content-wrapper" style="overflow-y: hidden">
    <div class="content-body">
      <!-- Products sell and New Orders -->
      <div class="row match-height">
        <div class="col-12">
          <div class="d-flex flex-row justify-start align-center mb-5">
            <h4 class="fw-600 mb-0">
              {{ $t("SigninInvoiceAndBank.invoice_info") }}
            </h4>
            <v-btn icon class="ml-3" @click="initAll()" :class="loadingInvoiceData && 'animate__spining'" :disabled="loadingInvoiceData">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <v-card v-if="loadingInvoiceData" tile class="card-header px-4 mb-5">
        <div class="">
          <v-skeleton-loader type="text@11, actions"></v-skeleton-loader>
        </div>
      </v-card>

      <v-card v-else tile class="card-header px-4 py-0 mb-5">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="controls">
                <div class="ml-2 mt-1 d-flex flex-wrap">
                  <v-radio-group row v-model="legal_entity" @change="resetInputValue()">
                    <v-radio
                      :label="$t('SigninInvoiceAndBank.legal_entity')"
                      :value="1"
                      color="#1e9ff2"
                    ></v-radio>
                    <v-radio
                      :label="$t('SigninInvoiceAndBank.natural_person')"
                      :value="2"
                      color="#1e9ff2"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="legal_entity == 1" class="row mt-2" id="show1">
          <div class="col-12">
            <div class="form-body ml-2">
              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="name"
                  >{{ $t("SigninInvoiceAndBank.legal_entity_name") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="name"
                      ref="name"
                      type="text"
                      class="form-control"
                      name="ipname"
                      v-model="ip_name"
                      style="min-height: 3rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!ip_name">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="address"
                  >{{ $t("SigninInvoiceAndBank.address") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="address"
                      ref="address"
                      type="text"
                      class="form-control"
                      name="ipaddress"
                      v-model="ip_address"
                      style="min-height: 3rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!ip_address">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="country"
                  >{{ $t("SigninInvoiceAndBank.country") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div
                    class="dropdown select"
                    @click="getfocus('selectcountry')"
                    @mouseleave="mouseOver('selectcountry')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectcountry">
                      <v-autocomplete
                        v-model="select_country"
                        :items="itemsCountry"
                        item-text="name"
                        item-value="value"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        autocomplete="off"
                        :loading="loading_country"
                        @mouseenter="getfocus('selectcountry')"
                        @mouseleave="mouseOver('selectcountry')"
                        @change="selectCountryFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!select_country">
                    {{ $t("Alert.pleasefilldata") }}
                  </span>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="city"
                  >{{ $t("SigninInvoiceAndBank.province") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div
                    class="dropdown select"
                    @click="getfocus('selectprovince')"
                    @mouseleave="mouseOver('selectprovince')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectprovince">
                      <v-autocomplete
                        v-model="select_province"
                        :items="itemsProvince"
                        item-text="province"
                        item-value="province_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        autocomplete="off"
                        :loading="loading_province"
                        @mouseenter="getfocus('selectprovince')"
                        @mouseleave="mouseOver('selectprovince')"
                        @change="selectProvinceFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!select_province">
                    {{ $t("Alert.pleasefilldata") }}
                  </span>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="city"
                  >{{ $t("SignupStep1.location3") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div
                    class="dropdown select"
                    @click="getfocus('selectamphoe')"
                    @mouseleave="mouseOver('selectamphoe')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectamphoe">
                      <v-autocomplete
                        v-model="select_amphoe"
                        :items="itemsAmphoe"
                        item-text="amphoe"
                        item-value="amphoe_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        autocomplete="off"
                        :loading="loading_amphoe"
                        @mouseenter="getfocus('selectamphoe')"
                        @mouseleave="mouseOver('selectamphoe')"
                        @change="selectAmphoeFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!select_amphoe">
                    {{ $t("Alert.pleasefilldata") }}
                  </span>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="city"
                  >{{ $t("SignupStep1.location4") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div
                    class="dropdown select"
                    @click="getfocus('selectdistrict')"
                    @mouseleave="mouseOver('selectdistrict')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectdistrict">
                      <v-autocomplete
                        v-model="select_district"
                        :items="itemsDistrict"
                        item-text="district"
                        item-value="district_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        autocomplete="off"
                        :loading="loading_district"
                        @mouseenter="getfocus('selectdistrict')"
                        @mouseleave="mouseOver('selectdistrict')"
                        @change="selectDistrictFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!select_district">
                    {{ $t("Alert.pleasefilldata") }}
                  </span>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="postcode"
                  >{{ $t("SigninInvoiceAndBank.postal_code") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="postcode"
                      type="text"
                      class="form-control"
                      name="ippostcode"
                      placeholder
                      v-model="ip_postcode"
                      @keypress="isNumber($event)"
                      maxlength="5"
                      style="min-height: 3rem; border: 1px solid var(--border-gray)"
                      :loading="loading_postcode"
                      @change="checkProvinceByPostcode()"
                    />
                    <span class="error-text-input" v-if="!ip_postcode">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="taxid"
                  >{{ $t("SigninInvoiceAndBank.tax_id") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="taxid"
                      ref="taxid"
                      type="text"
                      class="form-control"
                      name="iptaxid"
                      v-model="ip_taxid"
                      @keypress="isNumber($event)"
                      maxlength="13"
                      style="min-height: 3rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!ip_taxid">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="tel"
                  >{{ $t("SigninInvoiceAndBank.tel") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="tel"
                      ref="tel"
                      type="text"
                      class="form-control"
                      name="iptel"
                      v-model="ip_tel"
                      @keypress="isNumber($event)"
                      maxlength="10"
                      style="min-height: 3rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!ip_tel">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="email"
                  >{{ $t("SigninInvoiceAndBank.finance_email") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="email"
                      ref="email"
                      type="email"
                      class="form-control"
                      name="email"
                      v-model="email"
                      @keypress="isEmail($event)"
                      style="min-height: 2.625rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!email">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="taxid"
                >
                  {{ $t("SigninInvoiceAndBank.attach_file") }} ({{
                    $t("SigninInvoiceAndBank.pp20file")
                  }})
                </label>
                <div class="col-md-6 col-sm-12 col-12">
                  <!-- <label for="add-file" class="cursor-pointer">
                  <i class="la la-cloud-upload fw-600 font-50px"></i>
                  <input type="file" id="add-file" class="hidden" @change="handleFileChange(...arguments, item)" />
                  </label>-->
                  <!-- <div v-if="displayImg">
                    <input
                      type="file"
                      id="fileUpload"
                      accept="image/*"
                      style="display: none"
                      @change="uploadFileChangeHandler"
                    />
                    <img
                      :src="isImgEmpty(displayImg)"
                      class="img-thumbnail"
                      @click="uploadFileHandler()"
                    />
                  </div>
                  <v-file-input
                    v-else
                    chips
                    hide-input
                    accept="image/*"
                    @change="onFileChange"
                    class="mt-0 pt-0"
                  ></v-file-input>-->
                  <div
                    class="form-group"
                    @click="getfocus('selectfile')"
                    @mouseleave="mouseOver('selectfile')"
                  >
                    <a
                      data-toggle="dropdown"
                      data-display="static"
                      id="selectfile"
                      style="height: 3rem !important; border: none"
                      class="form-control pl-0"
                    >
                      <v-file-input
                        v-if="select_file"
                        v-model="select_file"
                        accept="application/pdf, .pdf"
                        show-size
                        counter
                        :label="$t('SigninInvoiceAndBank.pp20file')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                      ></v-file-input>
                      <div v-else class="d-flex flex-row align-center justify-start">
                        <v-file-input
                          v-model="select_file"
                          accept="application/pdf, .pdf"
                          hide-input
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                        ></v-file-input>
                        <span
                          style="position: absolute; left: 2rem"
                          v-if="old_select_file"
                          >{{ old_select_file }}</span
                        >
                        <span
                          style="position: absolute; left: 2rem; color: #6c757d"
                          v-else
                          >attach file (support .pdf file)</span
                        >
                      </div>
                    </a>
                  </div>
                  <span
                    class="error-text-input"
                    v-if="!select_file && !old_select_file"
                    >{{ $t("Alert.pleasefilldata") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="legal_entity == 2" class="row mt-2" id="show2">
          <div class="col-12">
            <div class="form-body ml-2">
              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="name"
                  >{{ $t("SigninInvoiceAndBank.fullname") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="name"
                      ref="name"
                      type="text"
                      class="form-control"
                      name="ipname"
                      v-model="ip_name"
                      style="min-height: 2.625rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!ip_name">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="address"
                  >{{ $t("SigninInvoiceAndBank.address") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="address"
                      ref="address"
                      type="text"
                      class="form-control"
                      name="ipaddress"
                      v-model="ip_address"
                      style="min-height: 2.625rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!ip_address">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="country"
                  >{{ $t("SigninInvoiceAndBank.country") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div
                    class="dropdown select"
                    @click="getfocus('selectcountry')"
                    @mouseleave="mouseOver('selectcountry')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectcountry">
                      <v-autocomplete
                        v-model="select_country"
                        :items="itemsCountry"
                        item-text="name"
                        item-value="value"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        autocomplete="off"
                        :loading="loading_country"
                        @mouseenter="getfocus('selectcountry')"
                        @mouseleave="mouseOver('selectcountry')"
                        @change="selectCountryFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!select_country">
                    {{ $t("Alert.pleasefilldata") }}
                  </span>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="city"
                  >{{ $t("SigninInvoiceAndBank.province") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div
                    class="dropdown select"
                    @click="getfocus('selectprovince')"
                    @mouseleave="mouseOver('selectprovince')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectprovince">
                      <v-autocomplete
                        v-model="select_province"
                        :items="itemsProvince"
                        item-text="province"
                        item-value="province_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        autocomplete="off"
                        :loading="loading_province"
                        @mouseenter="getfocus('selectprovince')"
                        @mouseleave="mouseOver('selectprovince')"
                        @change="selectProvinceFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!select_province">
                    {{ $t("Alert.pleasefilldata") }}
                  </span>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="city"
                  >{{ $t("SignupStep1.location3") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div
                    class="dropdown select"
                    @click="getfocus('selectamphoe')"
                    @mouseleave="mouseOver('selectamphoe')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectamphoe">
                      <v-autocomplete
                        v-model="select_amphoe"
                        :items="itemsAmphoe"
                        item-text="amphoe"
                        item-value="amphoe_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        autocomplete="off"
                        :loading="loading_amphoe"
                        @mouseenter="getfocus('selectamphoe')"
                        @mouseleave="mouseOver('selectamphoe')"
                        @change="selectAmphoeFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!select_amphoe">
                    {{ $t("Alert.pleasefilldata") }}
                  </span>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="city"
                  >{{ $t("SignupStep1.location4") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div
                    class="dropdown select"
                    @click="getfocus('selectdistrict')"
                    @mouseleave="mouseOver('selectdistrict')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="selectdistrict">
                      <v-autocomplete
                        v-model="select_district"
                        :items="itemsDistrict"
                        item-text="district"
                        item-value="district_code"
                        label
                        placeholder
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        autocomplete="off"
                        :loading="loading_district"
                        @mouseenter="getfocus('selectdistrict')"
                        @mouseleave="mouseOver('selectdistrict')"
                        @change="selectDistrictFunc()"
                      ></v-autocomplete>
                    </a>
                  </div>
                  <span class="error-text-input" v-if="!select_district">
                    {{ $t("Alert.pleasefilldata") }}
                  </span>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="postcode"
                  >{{ $t("SigninInvoiceAndBank.postal_code") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="postcode"
                      type="text"
                      class="form-control"
                      name="ippostcode"
                      placeholder
                      v-model="ip_postcode"
                      @keypress="isNumber($event)"
                      maxlength="5"
                      style="min-height: 2.625rem; border: 1px solid var(--border-gray)"
                      :loading="loading_postcode"
                      @change="checkProvinceByPostcode()"
                    />
                    <span class="error-text-input" v-if="!ip_postcode">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="taxid"
                  >{{ $t("SigninInvoiceAndBank.id_card_number") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="taxid"
                      ref="taxid"
                      type="text"
                      class="form-control"
                      name="iptaxid"
                      v-model="ip_taxid"
                      @keypress="isNumber($event)"
                      maxlength="13"
                      style="min-height: 2.625rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!ip_taxid">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="tel"
                  >{{ $t("SigninInvoiceAndBank.tel") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="tel"
                      ref="tel"
                      type="text"
                      class="form-control"
                      name="iptel"
                      v-model="ip_tel"
                      @keypress="isNumber($event)"
                      style="min-height: 2.625rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!ip_tel">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>


              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="email"
                  >{{ $t("SigninInvoiceAndBank.finance_email") }}</label
                >
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="form-group">
                    <input
                      id="email"
                      ref="email"
                      type="email"
                      class="form-control"
                      name="email"
                      v-model="email"
                      @keypress="isEmail($event)"
                      style="min-height: 2.625rem; border: 1px solid var(--border-gray)"
                    />
                    <span class="error-text-input" v-if="!email">
                      {{ $t("Alert.pleasefilldata") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <label
                  class="col-md-3 col-sm-12 col-12 label-control font-weight-bold"
                  for="taxid"
                >
                  {{ $t("SigninInvoiceAndBank.attach_file")
                  }}{{ $t("SigninInvoiceAndBank.copy_id_card_with_signature") }}
                </label>
                <div class="col-md-6 col-sm-12 col-12">
                  <!-- <label for="add-file" class="cursor-pointer">
                    <i class="la la-cloud-upload fw-600 font-50px"></i>
                    <input type="file" id="add-file" class="hidden" />
                  </label>-->
                  <!-- <div v-if="displayImg">
                    <input
                      type="file"
                      id="fileUpload"
                      accept="image/*"
                      style="display: none"
                      @change="uploadFileChangeHandler"
                    />
                    <img
                      :src="isImgEmpty(displayImg)"
                      class="img-thumbnail"
                      @click="uploadFileHandler()"
                    />
                  </div>
                  <v-file-input
                    v-else
                    chips
                    hide-input
                    accept="image/*"
                    @change="onFileChange"
                    class="mt-0 pt-0"
                  ></v-file-input>-->
                  <div
                    class="form-group"
                    @click="getfocus('selectfile')"
                    @mouseleave="mouseOver('selectfile')"
                  >
                    <a
                      data-toggle
                      data-display="static"
                      id="selectfile"
                      style="height: 3rem !important; border: none"
                      class="form-control pl-0"
                    >
                      <v-file-input
                        v-if="select_file"
                        v-model="select_file"
                        accept="application/pdf, .pdf"
                        show-size
                        counter
                        :label="$t('SigninInvoiceAndBank.copy_id_card_with_signature')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                      ></v-file-input>
                      <div v-else class="d-flex flex-row align-center justify-start">
                        <v-file-input
                          v-model="select_file"
                          accept="application/pdf, .pdf"
                          hide-input
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                        ></v-file-input>
                        <span
                          style="position: absolute; left: 2rem"
                          v-if="old_select_file"
                          >{{ old_select_file }}</span
                        >
                        <span
                          style="position: absolute; left: 2rem; color: #6c757d"
                          v-else
                          >attach file (support .pdf file)</span
                        >
                      </div>
                    </a>
                  </div>
                  <span
                    class="error-text-input"
                    v-if="!select_file && !old_select_file"
                    >{{ $t("Alert.pleasefilldata") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 py-3">
          <div class="row">
            <div class="col-md-12 px-2">
              <button
                class="btn btn-warning btn-min-width mb-1 gl-bt hover_btn mx-0"
                type="button"
                @click="gobackFunc()"
                :disabled="savingBtn"
              >
                <span>{{ $t("SigninInvoiceAndBank.btn_back") }}</span>
              </button>

              <button
                class="btn btn-info btn-min-width mb-1 gl-bt hover_btn mx-0 ml-3"
                type="button"
                @click="saveEditInvoiceInfoFunc()"
                :disabled="savingBtn"
              >
                <i v-if="savingBtn" class="fa fa-circle-o-notch fa-spin"></i>
                <span>{{ $t("SigninInvoiceAndBank.btn_save") }}</span>
              </button>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninEditInvoiceInfoScreen",
  metaInfo: {
    title: "Invoice & Bank",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    value_element: "",
    invoiceID: "",
    legal_entity: null,
    loading_country: true,
    loading_province: true,
    loading_amphoe: true,
    loading_district: true,
    loading_postcode: true,
    itemsDefaultCountry: [],
    itemsCountry: [],
    itemsDefaultProvince: [],
    itemsProvince: [],
    itemsAmphoe: [],
    itemsDistrict: [],
    ip_name: "",
    ip_address: "",
    ip_postcode: "",
    select_country: "",
    select_province: "",
    select_amphoe: "",
    select_district: "",
    ip_taxid: "",
    ip_tel: "",
    email: "",
    displayImg: null,
    old_select_file: null,
    select_file: null,
    maxImageWidth: 1920,
    maxImageHeight: 1080,
    itemInvoiceInfo: {},
    savingBtn: false,
    oldData: {},
    loadingInvoiceData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.invoiceID = self.$route.params.invoiceId;
    self.legal_entity = 1;
    self.initAll();
  },
  watch: {},
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    gobackFunc() {
      const link = {
        name: "SigninInvoiceAndBankScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
    },
    initAll() {
      const self = this;
      self.loadingInvoiceData = true;
      self.initCountry();
    },
    async fetchInvoiceInfo() {
      const self = this;
      self.loadingInvoiceData = true;
      self.itemInvoiceInfo = {};
      var tmpInvoiceInfo = null;

      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/finance/invoice?property=${
              self?.propertyID || self?.$route?.params?.propertyId
            }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        // console.log('get: ', res)
        if (res.status == 200) {
          tmpInvoiceInfo = res?.data?.result;
          // console.log(tmpInvoiceInfo);
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error);
      } finally {
        self.itemInvoiceInfo = tmpInvoiceInfo;
        self.oldData = tmpInvoiceInfo;
        // console.log(self.oldData)
        self.invoiceID = tmpInvoiceInfo?._id;
        self.legal_entity = tmpInvoiceInfo?.type || 1;
        self.ip_name = tmpInvoiceInfo?.name || "";
        self.ip_address = tmpInvoiceInfo?.address || "";
        self.ip_postcode = tmpInvoiceInfo?.postcode || "";
        self.select_country = tmpInvoiceInfo?.country || "";
        self.select_province = Number(tmpInvoiceInfo?.province) || "";
        self.selectProvinceFunc();
        self.select_amphoe = Number(tmpInvoiceInfo?.district) || "";
        self.selectAmphoeFunc();
        self.select_district = Number(tmpInvoiceInfo?.sub_district) || "";
        self.selectDistrictFunc();
        self.ip_taxid = tmpInvoiceInfo?.tax_number || "";
        self.ip_tel = tmpInvoiceInfo?.phone || "";
        self.displayImg = null;
        self.old_select_file = tmpInvoiceInfo?.file || null;
        self.select_file = null;
        self.email = tmpInvoiceInfo?.email_finance || "";
        // if (self.ip_postcode) self.checkProvinceByPostcode();

        // console.log(self.old_select_file);

        self.checkFillDataFunc(true);
        self.loadingInvoiceData = false;
        EventBus.$emit("endloading");
      }
    },
    async initCountry() {
      const self = this;
      var tempCountry = [];
      var temp = [];
      self.loading_country = true;
      self.loading_province = true;
      self.loading_amphoe = true;
      self.loading_district = true;
      self.loading_postcode = true;
      self.itemsCountry = [];
      self.itemsProvince = [];
      self.itemsAmphoe = [];
      self.itemsDistrict = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + `/setting/country`, {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || [],
              });
              if (el?.province?.length) {
                el?.province?.map((ele) => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || "",
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.loading_country = false;
        self.loading_province = false;
        self.loading_amphoe = false;
        self.loading_district = false;
        self.loading_postcode = false;
        self.itemsDefaultCountry = tempCountry;
        self.itemsCountry = tempCountry;
        // console.log(self.itemsCountry)
        self.itemsDefaultProvince = temp;
        // self.itemsProvince = temp;
        if (self.invoiceID) {
          self.itemsCountry = self.itemsDefaultCountry;
          self.itemsProvince = self.itemsDefaultProvince;
          // if (self.ip_postcode) self.checkProvinceByPostcode();
          self.fetchInvoiceInfo();
        } else self.loadingInvoiceData = false;
      }
    },
    selectCountryFunc() {
      const self = this;
      self.loading_province = true;
      var temp = [];
      self.itemsCountry?.map((el) => {
        if (el?.value == self.select_country && el?.province?.length) {
          el?.province?.map((ele) => {
            temp.push({
              district: ele?.subdistrict || "",
              amphoe: ele?.district || "",
              province: ele?.province || "",
              zipcode: ele?.zipcode || "",
              district_code: ele?.subdistrict_code || "",
              amphoe_code: ele?.district_code || "",
              province_code: ele?.province_code || "",
            });
          });
        }
      });
      temp.sort((a, b) => a.province.localeCompare(b.province, "th"));
      self.itemsProvince = temp;
      self.loading_province = false;
    },
    selectProvinceFunc() {
      const self = this;
      self.loading_amphoe = true;
      var temp = [];
      self?.itemsProvince?.map((el) => {
        if (
          el?.province_code === self?.select_province &&
          self.select_province &&
          self.select_province !== undefined
        ) {
          temp.push(el);
        }
      });
      self.itemsAmphoe = temp;
      self.loading_amphoe = false;
    },
    selectAmphoeFunc() {
      const self = this;
      self.loading_district = true;
      var temp = [];
      self?.itemsAmphoe?.map((el) => {
        if (
          el.province_code === self.select_province &&
          el.amphoe_code === self.select_amphoe &&
          self.select_province &&
          self.select_amphoe
        ) {
          temp.push(el);
        }
      });
      self.loading_district = false;
      self.itemsDistrict = temp;
    },
    selectDistrictFunc() {
      const self = this;
      self.loading_postcode = true;
      var temp = {};
      self?.itemsProvince?.map((el) => {
        if (
          el.province_code === self.select_province &&
          el.amphoe_code === self.select_amphoe &&
          el.district_code === self.select_district &&
          self.select_province &&
          self.select_amphoe &&
          self.select_district
        ) {
          temp = el;
        }
      });
      if (temp.zipcode) self.ip_postcode = temp.zipcode;
      self.loading_postcode = false;
    },
    checkProvinceByPostcode() {
      this.loading_country = true;
      this.loading_province = true;
      this.loading_amphoe = true;
      this.loading_district = true;
      this.loading_postcode = true;
      try {
        var tempProvince = [];
        var tempCountry = "";
        if (this.ip_postcode && this.ip_postcode.length == 5) {
          tempProvince = this.itemsDefaultProvince.filter(
            (p) => p.zipcode == this.ip_postcode
          );
          tempCountry = this.itemsCountry[0].value || "";
        }
        this.itemsProvince = this.ip_postcode ? tempProvince : this.itemsDefaultProvince;
        this.select_country = tempCountry;
        this.select_province = this.itemsProvince[0]?.province_code || "";
        this.select_amphoe = this.itemsProvince[0]?.amphoe_code || "";
        this.select_district = this.itemsProvince[0]?.district_code || "";
        this.selectProvinceFunc();
        this.selectAmphoeFunc();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading_country = false;
        this.loading_province = false;
        this.loading_amphoe = false;
        this.loading_district = false;
        this.loading_postcode = false;
      }
    },
    resetInputValue() {
      const self = this;
      // console.log(self.oldData)
      if (self.legal_entity == self.oldData?.type) {
        self.initAll();
        // self.loading_country = true;
        // self.loading_province = true;
        // self.loading_amphoe = true;
        // self.loading_district = true;
        // self.loading_postcode = true;

        // self.ip_name = self.oldData?.name || "";
        // self.ip_address = self.oldData?.address || "";
        // self.ip_postcode = self.oldData?.postcode || "";
        // self.select_country = self.oldData?.country || "";
        // self.selectCountryFunc();
        // self.select_province = Number(self.oldData?.province) || "";
        // self.selectProvinceFunc();
        // self.select_amphoe = Number(self.oldData?.district) || "";
        // self.selectAmphoeFunc();
        // self.select_district = Number(self.oldData?.sub_district) || "";
        // self.selectDistrictFunc();
        // self.ip_taxid = self.oldData?.tax_number || "";
        // self.ip_tel = self.oldData?.phone || "";
        // self.displayImg = null;
        // self.old_select_file = self.oldData?.file || null;
        // self.select_file = null;

        // setTimeout(() => {
        //   self.loading_country = false;
        //   self.loading_province = false;
        //   self.loading_amphoe = false;
        //   self.loading_district = false;
        //   self.loading_postcode = false;
        // }, 1000);

        self.checkFillDataFunc(true);
      } else {
        self.ip_name = "";
        self.ip_address = "";
        self.ip_postcode = "";
        self.itemsCountry = self.itemsDefaultCountry;
        self.itemsProvince = [];
        self.select_country = "";
        self.select_province = "";
        self.select_amphoe = "";
        self.select_district = "";
        self.ip_taxid = "";
        self.ip_tel = "";
        self.displayImg = null;
        self.select_file = null;
        self.old_select_file = null;
        self.email = "";
        self.checkFillDataFunc();
      }
    },
    alertDimensionLarge() {
      swal(
        this.$t("Alert.title_imgtoolarge"),
        this.$t("Alert.label_imgtoolarge"),
        this.$t("Alert.warn_label"),
        {
          button: false,
          timer: 3000,
        }
      );
    },
    alertSizeLarge() {
      swal(
        this.$t("Alert.title_imgtoolarge"),
        this.$t("Alert.label_imgtoolargesize"),
        this.$t("Alert.warn_label"),
        {
          button: false,
          timer: 3000,
        }
      );
    },
    uploadFileHandler() {
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler(event) {
      const vm = this;
      let img = event.target.files;
      // console.log("img: ", event, img);
      if (img.length > 0) {
        for (let i = 0; i < img.length; i++) {
          const reader = new FileReader();
          reader.onload = (e) => {
            vm.displayImg = e.target.result;
          };
          reader.readAsDataURL(img[i]);
        }

        if (img[0]?.width > this.maxImageWidth || img[0].height > this.maxImageHeight) {
          vm.select_file = null;
          vm.displayImg = "";
          vm.alertDimensionLarge();
        } else if (img[0]?.size > 1024 * 1024 * 1 /*1MB*/) {
          vm.select_file = null;
          vm.displayImg = "";
          vm.alertSizeLarge();
        } else {
          vm.select_file = img[0];
        }
      } else {
        this.select_file = null;
      }
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.displayImg = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(file) {
      // console.log(file);
      if (!file) {
        return;
      } else if (file?.width > this.maxImageWidth || file.height > this.maxImageHeight) {
        this.alertDimensionLarge();
        return;
      } else if (file?.size > 1024 * 1024 * 1 /*1MB*/) {
        this.alertSizeLarge();
        return;
      } else if (file !== "undefined") {
        this.select_file = file;
      }
      this.createImage(file);
    },
    isImgEmpty(path) {
      if (path) {
        return path;
      }
      return require("@/assets/icons/icon-noimage.png");
    },
    checkFillDataFunc(check) {
      const self = this;
      var elementName = document.getElementById("name");
      var elementAddress = document.getElementById("address");
      var elementCountry = document.getElementById("selectcountry");
      var elementProvince = document.getElementById("selectprovince");
      var elementAmphoe = document.getElementById("selectamphoe");
      var elementDistrict = document.getElementById("selectdistrict");
      var elementPostcode = document.getElementById("postcode");
      var elementTax = document.getElementById("taxid");
      var elementTel = document.getElementById("tel");
      var elementEmail = document.getElementById("email");
      var elementFile = document.getElementById("selectfile");

      if (check) {
        if (!self.ip_name || self.ip_name == "") {
          elementName.classList.add("border-pink-focus");
        } else {
          elementName?.classList?.remove("border-pink-focus");
        }
        if (!self.ip_address || self.ip_address == "") {
          elementAddress.classList.add("border-pink-focus");
        } else {
          elementAddress?.classList?.remove("border-pink-focus");
        }
        if (!self.select_country || self.select_country == "") {
          elementCountry.classList.add("border-pink-focus");
        } else {
          elementCountry?.classList?.remove("border-pink-focus");
        }
        if (!self.select_province || self.select_province == "") {
          elementProvince.classList.add("border-pink-focus");
        } else {
          elementProvince?.classList?.remove("border-pink-focus");
        }
        if (!self.select_amphoe || self.select_amphoe == "") {
          elementAmphoe.classList.add("border-pink-focus");
        } else {
          elementAmphoe?.classList?.remove("border-pink-focus");
        }
        if (!self.select_district || self.select_district == "") {
          elementDistrict.classList.add("border-pink-focus");
        } else {
          elementDistrict?.classList?.remove("border-pink-focus");
        }
        if (!self.ip_postcode || self.ip_postcode == "" || self.ip_postcode?.length < 5) {
          elementPostcode.classList.add("border-pink-focus");
        } else {
          elementPostcode?.classList?.remove("border-pink-focus");
        }
        if (!self.ip_taxid || self.ip_taxid == "" || self.ip_taxid?.length < 13) {
          elementTax.classList.add("border-pink-focus");
        } else {
          elementTax?.classList?.remove("border-pink-focus");
        }
        if (!self.ip_tel || self.ip_tel == "" || self.ip_taxid?.ip_tel < 9) {
          elementTel.classList.add("border-pink-focus");
        } else {
          elementTel?.classList?.remove("border-pink-focus");
        }
        if (!self.email || self.email == "") {
          elementEmail.classList.add("border-pink-focus");
        } else {
          elementEmail?.classList?.remove("border-pink-focus");
        }
        if (
          (!self.select_file && !self.old_select_file) ||
          (self.select_file == "" && self.old_select_file == "")
        ) {
          elementFile.classList.add("border-pink-focus");
        } else {
          elementFile?.classList?.remove("border-pink-focus");
        }
      } else {
        elementName?.classList?.remove("border-pink-focus");
        elementAddress?.classList?.remove("border-pink-focus");
        elementCountry?.classList?.remove("border-pink-focus");
        elementProvince?.classList?.remove("border-pink-focus");
        elementAmphoe?.classList?.remove("border-pink-focus");
        elementDistrict?.classList?.remove("border-pink-focus");
        elementPostcode?.classList?.remove("border-pink-focus");
        elementTax?.classList?.remove("border-pink-focus");
        elementTel?.classList?.remove("border-pink-focus");
        elementEmail?.classList?.remove("border-pink-focus");
        elementFile?.classList?.remove("border-pink-focus");
      }
    },
    saveEditInvoiceInfoFunc() {
      const self = this;
      const obj = {
        type: self.legal_entity,
        name: self.ip_name,
        address: self.ip_address,
        country: self.select_country,
        province: self.select_province,
        district: self.select_amphoe,
        sub_district: self.select_district,
        postcode: self.ip_postcode,
        tax_number: self.ip_taxid,
        phone: self.ip_tel,
        email_finance: self.email,
      };

      self.checkFillDataFunc(true);

      if (
        !self.ip_name ||
        !self.ip_address ||
        !self.select_country ||
        !self.select_province ||
        !self.select_amphoe ||
        !self.select_district ||
        !self.ip_postcode ||
        self.ip_postcode?.length < 5 ||
        !self.ip_taxid ||
        self.ip_taxid?.length < 13 ||
        !self.ip_tel ||
        self?.ip_tel < 9 || 
        !self.email || 
        self.email == "" ||
        (!self.select_file && !self.old_select_file)
      ) {
        swal(
          self.$t("Alert.warn_title"),
          `${self.$t("Alert.pleasefilldata").replace("*", "")}${
            !self.ip_name || self.ip_name == ""
              ? ` ${self.$t("SigninInvoiceAndBank.legal_entity_name")}`
              : ""
          }${
            !self.ip_address || self.ip_address == ""
              ? ` ${self.$t("SigninInvoiceAndBank.address")}`
              : ""
          }${
            !self.select_country || self.select_country == ""
              ? ` ${self.$t("SigninInvoiceAndBank.country")}`
              : ""
          }${
            !self.select_province || self.select_province == ""
              ? ` ${self.$t("SigninInvoiceAndBank.province")}`
              : ""
          }${
            !self.select_amphoe || self.select_amphoe == ""
              ? ` ${self.$t("SignupStep1.location3")}`
              : ""
          }${
            !self.select_district || self.select_district == ""
              ? ` ${self.$t("SignupStep1.location4")}`
              : ""
          }${
            !self.ip_postcode || self.ip_postcode == "" || self.ip_postcode?.length < 5
              ? ` ${self.$t("SigninInvoiceAndBank.postal_code")}`
              : ""
          }${
            !self.ip_taxid || self.ip_taxid == ""
              ? ` ${self.$t("SigninInvoiceAndBank.tax_id")}`
              : ""
          }${
            self.ip_taxid?.length < 13
              ? ` ${self.$t("SigninInvoiceAndBank.tax_id")}${self.$t("Alert.invalid")}`
              : ""
          }${
            !self.ip_tel || self.ip_tel == "" || self.ip_taxid?.ip_tel < 9
              ? ` ${self.$t("SigninInvoiceAndBank.tel")}`
              : ""
          }${
            self.email == ""
              ? ` ${self.$t("SigninInvoiceAndBank.finance_email")}`
              : ""
          }${!self.select_file ? ` ${self.$t("SigninInvoiceAndBank.attach_file")}` : ""}`,
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else if (self.oldData?._id && self.legal_entity !== self.oldData?.type) {
        swal(
          `${self.$t("SigninInvoiceAndBank.change_legal_switch1")} ${
            self.oldData?.type == 1
              ? self.$t("SigninInvoiceAndBank.change_legal_switch3")
              : self.$t("SigninInvoiceAndBank.change_legal_switch2")
          } ${self.$t("SigninInvoiceAndBank.change_legal_switch4")}`,
          {
            buttons: {
              cancel: self.$t("SignupStep7.btn_no"),
              confirm: {
                text: self.$t("SignupStep7.btn_yes"),
                value: "confirm",
              },
            },
          }
        ).then((value) => {
          if (value === "confirm") {
            self.confirmUploadFileAndDataFunc(obj);
          }
        });
      } else {
        self.confirmUploadFileAndDataFunc(obj);
      }
    },
    async confirmUploadFileAndDataFunc(obj) {
      const self = this;
      var filename = null;
      var formData = new FormData();
      if (self.select_file) {
        formData.append("file", self.select_file);
        filename = self.select_file.name;
      }
      EventBus.$emit("inProgressing");
      EventBus.$emit("loadingtillend");
      self.savingBtn = true;
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + `/setting/upload`,
          formData,
          {
            headers: {
              Authorization: self?.userToken,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
            // timeout: 3 * 60
          }
        );
        // console.log("upload: ", res);
      } catch (error) {
        EventBus.$emit("endloading");
        EventBus.$emit("endProgress");
        self.savingBtn = false;
        console.log(error);
      } finally {
        // console.log("filename: ", self.select_file, filename);
        self.confirmSave(obj, filename);
      }
    },
    async confirmSave(obj, filename) {
      const self = this;
      if (filename) obj.file = filename;
      EventBus.$emit("inProgressing");
      EventBus.$emit("loadingtillend");
      self.savingBtn = true;
      if (self.invoiceID) {
        try {
          const res = await self.axios.put(
            process.env.VUE_APP_API + `/finance/invoice?invoice_id=${self?.invoiceID}`,
            obj,
            {
              headers: {
                Authorization: self?.userToken,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
          // console.log("update: ", res);
          if (res?.status == 200) {
            EventBus.$emit("endloading");
            EventBus.$emit("endProgress");
            self.savingBtn = false;
            swal(
              self.$t("Alert.success_title"),
              self.$t("Alert.save_success"),
              self.$t("Alert.success_label"),
              {
                button: false,
                timer: 3000,
              }
            ).then(() => {
              var item = {
                link: `finance/invoiceandbank/${self.userToken}/${self.propertyID}`,
                name: "SigninInvoiceAndBankScreen",
                params: {
                  userToken: self.userToken,
                  propertyId: self.propertyID,
                },
              };
              EventBus.$emit("changePathname", item);
            });
          }
        } catch (error) {
          EventBus.$emit("endloading");
          EventBus.$emit("endProgress");
          self.savingBtn = false;
          console.log(error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          EventBus.$emit("endloading");
          EventBus.$emit("endProgress");
          self.savingBtn = false;
        }
      } else {
        try {
          const res = await self.axios.post(
            process.env.VUE_APP_API + `/finance/invoice?property_id=${self.propertyID}`,
            obj,
            {
              headers: {
                Authorization: self?.userToken,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
          // console.log("new: ", res);
          if (res?.status == 200) {
            EventBus.$emit("endloading");
            EventBus.$emit("endProgress");
            self.savingBtn = false;
            swal(
              self.$t("Alert.success_title"),
              self.$t("Alert.save_success"),
              self.$t("Alert.success_label"),
              {
                button: false,
                timer: 3000,
              }
            ).then(() => {
              var item = {
                link: `finance/invoiceandbank/${self.userToken}/${self.propertyID}`,
                name: "SigninInvoiceAndBankScreen",
                params: {
                  userToken: self.userToken,
                  propertyId: self.propertyID,
                },
              };
              EventBus.$emit("changePathname", item);
            });
          }
        } catch (error) {
          EventBus.$emit("endloading");
          EventBus.$emit("endProgress");
          self.savingBtn = false;
          console.log(error);
          swal(
            self.$t("Alert.error_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.error_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          EventBus.$emit("endloading");
          EventBus.$emit("endProgress");
          self.savingBtn = false;
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";
@import "../../../../styles/signin/finance.css";
@import "../../../../styles/signin/invoiceandbank.css";
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
@import "../../../../styles/bootstrap/bootstrap.css";
@import "../../../../styles/bootstrap/colors.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}

.img-thumbnail {
  max-width: 15rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
